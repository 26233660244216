import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from 'src/translations/de';
import en from 'src/translations/en';
import AuthContextProvider from './contexts/authContext';
import MarketContextProvider from './contexts/marketContext';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const Layout = React.lazy(() => import('./layout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        de: { translation: de },
    },
    lng: 'de',
    fallbackLng: 'de',
    interpolation: { escape: false },
});

class App extends Component {
    render() {
        return (
            <HashRouter>
                <AuthContextProvider>
                    <MarketContextProvider>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    name="Login Page"
                                    render={(props) => <Login {...props} />}
                                />
                                <Route
                                    exact
                                    path="/404"
                                    name="Page 404"
                                    render={(props) => <Page404 {...props} />}
                                />
                                <Route
                                    exact
                                    path="/500"
                                    name="Page 500"
                                    render={(props) => <Page500 {...props} />}
                                />
                                <Route
                                    exact
                                    path="/forgot-password"
                                    name="Reset Password"
                                    render={(props) => <ForgotPassword {...props} />}
                                />

                                <Route
                                    path="/"
                                    name="Home"
                                    render={(props) => <Layout {...props} />}
                                />
                            </Switch>
                        </React.Suspense>
                    </MarketContextProvider>
                </AuthContextProvider>
            </HashRouter>
        );
    }
}

export default App;
