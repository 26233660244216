import React, { createContext, useState, useEffect } from 'react';

export const MarketContext = createContext({
    market: '',
    mandant: '',
    lang: '',
    setMarketToSwiss: () => {},
    setMarketToUs: () => {},
});

export const MarketContextProvider = ({ children }) => {
    const [market, setMarket] = useState('');
    const [mandant, setMandat] = useState(null);
    const [lang, setLang] = useState('');

    const setMarketToSwissHandler = () => {
        setMarket('CH');
        setLang('DE');
        setMandat(1);
        localStorage.setItem('market', 'CH');
    };

    const setMarketToUsHandler = () => {
        setMarket('US');
        setLang('EN');
        setMandat(2);
        localStorage.setItem('market', 'US');
    };

    useEffect(() => {
        let localMarket = localStorage.getItem('market');

        if (!localMarket) {
            setMarketToSwissHandler();
            return;
        }

        localMarket === 'CH' ? setMarketToSwissHandler() : setMarketToUsHandler();
    }, []);

    return (
        <MarketContext.Provider
            value={{
                market,
                mandant,
                lang,
                setMarketToSwiss: setMarketToSwissHandler,
                setMarketToUs: setMarketToUsHandler,
            }}
        >
            {children}
        </MarketContext.Provider>
    );
};

export default MarketContextProvider;
