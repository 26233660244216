import api from './apiLibrary';

const API = {
    logIn(user) {
        return api.post('/api/auth/login-two-factor-1', user);
    },
    twoFactorAuthentication(user) {
        return api.post('/api/auth/login-two-factor-2', user);
    },
    logOut() {
        return api.post('/api/auth/logout');
    },
    getLoggedInUser() {
        return api.get('/api/user/me');
    },
    // validateCaptcha(token){
    //   return api.post(`https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_SECRET_KEY}&response=${token}`);
    // }
    forgotPassword(user) {
        return api.post('/api/auth/forgot-password-request', user);
    },
    resetPassword(user) {
        return api.post('/api/auth/forgot-password-response', user);
    },
    resendCode(user) {
        return api.post('/api/auth/resend-two-factor-1-code', user);
    },
};
export default API;
